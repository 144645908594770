import { useState, useEffect } from "react";

interface WindowSize {
  isMobile: boolean;
}

export function useWindowSize(): WindowSize {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isMobile };
}
