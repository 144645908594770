import "./page.css";

/* utils */

import { useWindowSize } from "../hooks/useWindowSize";

/* components */
import Home from "../components/home";
import Emojis from "../components/emojis";
import AboutSection1 from "../components/about-section1";
import AboutSection2 from "../components/about-section2";
import AboutSection3 from "../components/about-section3";
import AboutSection4 from "../components/about-section4";
import AboutSection5 from "../components/about-section5";
import Footer from "../components/footer";

export default function Page() {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <header>
        <Home />
      </header>
      {isMobile && <Emojis />}
      <main>
        <AboutSection1 />
        <AboutSection2 />
        <AboutSection3 />
        <AboutSection4 />
        <AboutSection5 />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
