import heart from "../../assets/animated emojis/heart.png";
import smiley from "../../assets/animated emojis/smiley.png";
import camera from "../../assets/animated emojis/camera.png";
import lovely from "../../assets/animated emojis/lovely.png";

import "./styles.css";

export default function Emojis() {
  return (
    <div className="emojis-container d-mobile-only">
      <img src={heart} alt="heart" className="heart" width={127} height={133} />
      <img
        src={smiley}
        alt="smiley"
        className="smiley"
        width={206}
        height={206}
      />
      <img
        src={camera}
        alt="camera"
        className="camera"
        width={118}
        height={112}
      />
      <img
        src={lovely}
        alt="lovely"
        className="lovely"
        width={122}
        height={112}
      />
    </div>
  );
}
