import CustomImage from "../customImage/customImage";
import { useWindowSize } from "../../hooks/useWindowSize";

import backgroundImage from "../../assets/background-images/bg-3/laptop.png";
import mobileBackgroundImage from "../../assets/background-images/bg-3/laptop-mobile.png";

export default function AboutSection2() {
  const { isMobile } = useWindowSize();

  const pcSrc = backgroundImage;
  const mobileSrc = mobileBackgroundImage;
  const imageSrc = isMobile ? mobileSrc : pcSrc;
  return (
    <>
      <section className="d-flex-lg section-container section-container-order margin-bottom-ipad">
        <div className="div-section">
          <h2 className="font-bold font-color-primary ">
            Join Us for <span className="font-color-secondary">Free!</span>
          </h2>
          <p className="font-color-primary ">
            To celebrate, we're offering our Starter Package free to select
            brands, with a 2499 AED content management fee. Join alist.ae and
            creators.ae at a partner price in 2025.
          </p>
          <div className="margin-top-40 margin-bottom-60">
            <div className="button-primary">
              <a
                href="https://business.alist.ae/forms/registration-form"
                target="_blank"
                rel="noreferrer"
              >
                Register Now
              </a>
            </div>
          </div>
        </div>
        <div className="width-ipad-2">
          <CustomImage
            src={imageSrc}
            alt={"..."}
            loading={"lazy"}
            imageWidth={645}
            imageHeight={465}
            width={594}
            height={594}
            spinnerWidth={594}
            spinnerHeight={594}
            gradient="secondary"
            marginRight="-10px"
            mobileWidth={430} // Adjust the width for mobile
            mobileHeight={420.54} // Adjust the height for mobile
            mobileSpinnerWidth={457.33} // Adjust the spinner width for mobile
            mobileSpinnerHeight={420.54} // Adjust the spinner height for mobile
            mediumSpinnerWidth={400}
            mediumSpinnerHeight={400}
            mediumWidth={400}
            mediumHeight={400}
          />
        </div>
      </section>
    </>
  );
}
