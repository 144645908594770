import CustomImage from "../customImage/customImage";
import { useWindowSize } from "../../hooks/useWindowSize";

import backgroundImage from "../../assets/background-images/bg-5/angel.png";
import mobileBackgroundImage from "../../assets/background-images/bg-5/angel-mobile.png";

export default function AboutSection4() {
  const { isMobile } = useWindowSize();

  const pcSrc = backgroundImage;
  const mobileSrc = mobileBackgroundImage;
  const imageSrc = isMobile ? mobileSrc : pcSrc;
  return (
    <>
      <section className="d-flex-lg section-container margin-top-60 margin-bottom-ipad">
        <CustomImage
          src={imageSrc}
          alt={"..."}
          loading={"lazy"}
          width={633}
          height={633}
          spinnerWidth={633}
          spinnerHeight={633}
          gradient="third"
          marginLeft="-50px"
          mobileWidth={439} // Adjust the width for mobile
          mobileHeight={439} // Adjust the height for mobile
          mobileSpinnerWidth={439} // Adjust the spinner width for mobile
          mobileSpinnerHeight={439} // Adjust the spinner height for mobile
          mediumSpinnerWidth={400}
          mediumSpinnerHeight={400}
          mediumWidth={400}
          mediumHeight={400}
        />
        <div className="div-section">
          <h2 className="font-bold font-color-primary">
            Help Us <span className="font-color-secondary">Grow</span>
          </h2>
          <p className="font-color-primary">
            We only ask for a testimonial and review in return. Your feedback is
            crucial for us to improve our platform for creators and brands.
          </p>
        </div>
      </section>
    </>
  );
}
