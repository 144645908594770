import { useState, useEffect } from "react";
import "./customImage.css";

interface CustomImageProps {
  src: string;
  alt: string;
  className?: string;
  gradient?: "primary" | "secondary" | "third";
  loading?: "lazy" | "eager";
  imageWidth?: number;
  imageHeight?: number;
  width?: number | string;
  height?: number | string;
  spinnerWidth?: number | string;
  spinnerHeight?: number | string;
  marginLeft?: string;
  marginRight?: string;
  mobileSrc?: string;
  mobileWidth?: number | string;
  mobileHeight?: number | string;
  mobileSpinnerWidth?: number | string;
  mobileSpinnerHeight?: number | string;
  mobileMarginLeft?: string;
  mobileMarginRight?: string;
  mediumWidth?: number | string;
  mediumHeight?: number | string;
  mediumSpinnerWidth?: number | string;
  mediumSpinnerHeight?: number | string;
  mediumMarginLeft?: string;
  mediumMarginRight?: string;
}

export default function CustomImage({
  src,
  alt,
  className,
  loading,
  width = "100%",
  height = "auto",
  imageWidth,
  imageHeight,
  spinnerWidth = "100%",
  spinnerHeight = "auto",
  gradient,
  marginLeft,
  marginRight,
  mobileSrc,
  mobileWidth,
  mobileHeight,
  mobileSpinnerWidth,
  mobileSpinnerHeight,
  mobileMarginLeft,
  mobileMarginRight,
  mediumWidth,
  mediumHeight,
  mediumSpinnerWidth,
  mediumSpinnerHeight,
  mediumMarginLeft,
  mediumMarginRight,
}: CustomImageProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMediumSize, setIsMediumSize] = useState(false);
  const [isSmallSize, setIsSmallSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsMediumSize(window.innerWidth > 768 && window.innerWidth <= 1250);
      setIsSmallSize(window.innerWidth <= 420);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = isMobile && mobileSrc ? mobileSrc : src;
  }, [src, mobileSrc, isMobile]);

  const renderGradient = () => {
    const effectiveWidth = isSmallSize
      ? "90%"
      : isMobile
      ? mobileWidth || width
      : isMediumSize
      ? mediumWidth || width
      : width;
    const effectiveHeight = isMobile
      ? mobileHeight || height
      : isMediumSize
      ? mediumHeight || height
      : height;

    switch (gradient) {
      case "primary":
        return (
          <>
            <div className="gradient-background-global gradient-background-primary" />
            <div className="gradient-background-global gradient-background-second" />
          </>
        );
      case "secondary":
        return (
          <div
            className="gradient-background-global gradient-background-secondary"
            style={{
              width: effectiveWidth,
              height: effectiveHeight,
            }}
          />
        );
      case "third":
        return (
          <div
            className="gradient-background-global gradient-background-third"
            style={{
              width: effectiveWidth,
              height: effectiveHeight,
            }}
          />
        );
      default:
        return null;
    }
  };

  const containerStyle = {
    width: isMobile
      ? mobileSpinnerWidth !== undefined
        ? mobileSpinnerWidth
        : spinnerWidth
      : isMediumSize
      ? mediumSpinnerWidth !== undefined
        ? mediumSpinnerWidth
        : spinnerWidth
      : spinnerWidth,
    height: isMobile
      ? mobileSpinnerHeight !== undefined
        ? mobileSpinnerHeight
        : spinnerHeight
      : isMediumSize
      ? mediumSpinnerHeight !== undefined
        ? mediumSpinnerHeight
        : spinnerHeight
      : spinnerHeight,
    ...(isMobile &&
      mobileMarginLeft !== undefined && { marginLeft: mobileMarginLeft }),
    ...(isMobile &&
      mobileMarginRight !== undefined && { marginRight: mobileMarginRight }),
    ...(isMediumSize &&
      mediumMarginLeft !== undefined && { marginLeft: mediumMarginLeft }),
    ...(isMediumSize &&
      mediumMarginRight !== undefined && { marginRight: mediumMarginRight }),
    ...(!isMobile &&
      !isMediumSize &&
      marginLeft !== undefined && { marginLeft }),
    ...(!isMobile &&
      !isMediumSize &&
      marginRight !== undefined && { marginRight }),
    ...(isSmallSize && width !== "100%" && { width: "90%" }),
  };

  const innerContainerStyle = {
    width: isMobile
      ? mobileWidth !== undefined
        ? mobileWidth
        : width
      : isMediumSize
      ? mediumWidth !== undefined
        ? mediumWidth
        : width
      : width,
    height: isMobile
      ? mobileHeight !== undefined
        ? mobileHeight
        : height
      : isMediumSize
      ? mediumHeight !== undefined
        ? mediumHeight
        : height
      : height,
    ...(isSmallSize && { width: "100%" }),
  };

  return (
    <div className="customImage-container" style={containerStyle}>
      <div style={innerContainerStyle}>
        {!imageLoaded && (
          <div className="spinner-container" style={innerContainerStyle}>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {imageLoaded && renderGradient()}
        <img
          src={isMobile && mobileSrc ? mobileSrc : src}
          alt={alt}
          className={`customImage ${className || ""}`}
          width={imageWidth}
          height={imageHeight}
          loading={loading}
          style={{ display: imageLoaded ? "block" : "none" }}
        />
      </div>
    </div>
  );
}
