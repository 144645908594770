import { useWindowSize } from "../../hooks/useWindowSize";
import CustomImage from "../customImage/customImage";
import backgroundImage from "../../assets/background-images/bg-1/bg.webp";

export default function Home() {
  const { isMobile } = useWindowSize();

  return (
    <div className="margin-bottom-ipad">
      <div className="logo ">
        <img
          src="/logo/logo.png"
          alt="Logo"
          width={107}
          height={41.73}
          loading="eager"
        />
      </div>
      <section className="d-flex-lg">
        <div className="margin-top-home">
          <div>
            <h1 className="font-color-primary">
              Celebrating a major milestone with
              <span className="font-bold d-block font-color-primary">
                our <span className="font-color-secondary">community</span>
              </span>
            </h1>
            <p className=" font-color-primary w-85 margin-30">
              Join Us in Revolutionizing the Future of Creator Collaborations
              and Partnerships
            </p>
          </div>
          <div className="d-flex gap-mid line-height-reduced justify-content-center-mb">
            <div>
              <div className=" font-color-primary font-size-stats font-demi-bold">
                50K <span className="font-color-secondary">+</span>
              </div>
              <div className=" font-color-alt font-size-stats-secondary">
                Creators
              </div>
            </div>
            <div>
              <div className=" font-color-primary font-size-stats font-demi-bold">
                2.5K<span className="font-color-secondary">+</span>
              </div>
              <div className=" font-color-alt font-size-stats-secondary">
                Brands
              </div>
            </div>
            <div>
              <div className=" font-color-primary font-size-stats font-demi-bold">
                50K<span className="font-color-secondary">+</span>
              </div>
              <div className=" font-color-alt font-size-stats-secondary">
                Campaigns
              </div>
            </div>
          </div>
          <div className="margin-60">
            <div className="button-primary ">
              <a
                href="https://business.alist.ae/forms/registration-form"
                target="_blank"
                rel="noreferrer"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div>
            <CustomImage
              src={backgroundImage}
              alt={"..."}
              loading={"eager"}
              imageWidth={606}
              imageHeight={764}
              width={606}
              height={764}
              spinnerHeight={979}
              spinnerWidth={757}
              gradient="primary"
              marginRight="-200px"
              mediumSpinnerWidth={400}
              mediumSpinnerHeight={600}
              mediumWidth={400}
              mediumHeight={600}
            />
          </div>
        )}
      </section>
    </div>
  );
}
