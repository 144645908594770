import heart from "../../assets/logo/heart.svg";
export default function AboutSection5() {
  return (
    <>
      <section className="margin-top-60 margin-top-100-mobile margin-bottom-above-footer">
        <div className="section-container text-align-center">
          <h2 className="font-bold font-color-primary">
            Thank You{" "}
            <span>
              <img src={heart} alt="heart" width={30.72} height={27.63} />
            </span>
          </h2>
          <p className="font-color-primary">
            To our partners, creators, and the UAE: Your support made this
            possible. Here's to the future of media!
          </p>
        </div>
      </section>
    </>
  );
}
