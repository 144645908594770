import { useWindowSize } from "../../hooks/useWindowSize";
import CustomImage from "../customImage/customImage";

import backgroundImage from "../../assets/background-images/bg-2/gift.png";
import mobileBackgroundImage from "../../assets/background-images/bg-2/gift-mobile.png";
export default function AboutSection1() {
  const { isMobile } = useWindowSize();

  const pcSrc = backgroundImage;
  const mobileSrc = mobileBackgroundImage;
  const imageSrc = isMobile ? mobileSrc : pcSrc;
  return (
    <>
      <section className="d-flex-lg section-container gap-1 margin-bottom-ipad">
        <CustomImage
          src={imageSrc}
          alt={"..."}
          className={""}
          loading={"lazy"}
          imageWidth={540}
          imageHeight={540}
          width={540}
          height={540}
          spinnerHeight={540}
          spinnerWidth={540}
          marginLeft="-30px"
          mobileWidth={332}
          mobileHeight={332}
          mobileSpinnerWidth={332}
          mobileSpinnerHeight={332}
          mediumSpinnerWidth={400}
          mediumSpinnerHeight={400}
          mediumWidth={400}
          mediumHeight={400}
        />
        <div className="div-section">
          <h2 className="font-bold font-color-primary">
            We've Raised <span className="font-color-secondary">Millions!</span>
          </h2>
          <p className="font-color-primary">
            We're excited to announce millions in new funding, thanks to our
            creators and partners. To give back, we're offering select brands
            with strong social media a chance to join our platform and
            experience the future of creator collaborations.
          </p>
        </div>
      </section>
    </>
  );
}
