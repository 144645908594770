import CustomImage from "../customImage/customImage";
import { useWindowSize } from "../../hooks/useWindowSize";

import backgroundImage from "../../assets/background-images/bg-4/globe.png";
import mobileBackgroundImage from "../../assets/background-images/bg-4/globe-mobile.png";

export default function AboutSection3() {
  const { isMobile } = useWindowSize();

  const pcSrc = backgroundImage;
  const mobileSrc = mobileBackgroundImage;
  const imageSrc = isMobile ? mobileSrc : pcSrc;
  return (
    <>
      <section className="section-container-order margin-bottom-ipad">
        <div className="section-container text-align-center margin-top-60 margin-bottom-60 margin-mobile-only-35 margin-mobile-only-50">
          <h2 className="font-bold font-color-primary">
            <span className="font-color-secondary">See You</span> in Los
            Angeles, London, New York, Saudi Arabia early 2025.
          </h2>
          <p className="font-color-primary">
            We are gearing up for our{" "}
            <span className="font-demi-bold">global launch in 2025</span>,
            bringing the biggest creators hub in the world to these iconic
            cities.
          </p>
        </div>
        <CustomImage src={imageSrc} alt={"..."} loading={"lazy"} />
      </section>
    </>
  );
}
